import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import LPContainer from '~/components/LandingPage/LPContainer'
import { ICONS_SOCIAL } from '~/core/constants/social'
import {
  ATTRACT_SOURCE_URL,
  CONTACT_US_URL,
  FEATURES_URL,
  HIRE_ANALYTICS_URL,
  HR_OKR_EBOOK_URL,
  PRICING_URL,
  SMALL_BUSINESS_URL,
  STARTUP_URL,
  TRACK_COLLABORATE_URL,
  URL_BLOG,
  URL_HELP_CENTER,
  URL_LOGIN_APP,
  URL_REGISTER_APP
} from '~/core/constants/url'
import { Button } from '~/core/ui/Button'
import NavigationBar, { NavItemsProps } from './NavigationBar'
import MobileNavigation from './MobileNavigation'
import { cn } from '~/core/ui/utils'
import useScrollPosition from '~/lib/hooks/use-scroll-position'

const navContent: NavItemsProps = [
  {
    label: 'footer:product',
    href: PRICING_URL,
    target: '_self',
    subItems: [
      {
        label: 'footer:attract_source',
        subLabel: 'nav:find_best_talents',
        icon: 'search_nav',
        href: ATTRACT_SOURCE_URL
      },
      {
        label: 'footer:track_collaboration',
        href: TRACK_COLLABORATE_URL,
        subLabel: 'nav:work_with_your_team',
        icon: 'track_nav'
      },
      {
        label: 'footer:hire_analytics',
        href: HIRE_ANALYTICS_URL,
        subLabel: 'nav:data_driven_hiring',
        icon: 'hire_nav'
      },
      { label: 'footer:all_features', href: FEATURES_URL, icon: 'all_feat_nav' }
    ],
    rightContent: {
      title: 'footer:hireforce_for',
      subItems: [
        {
          label: 'nav:start_up_label',
          subLabel: 'nav:start_up_sub_label',
          href: STARTUP_URL
        },
        {
          label: 'nav:small_business_label',
          subLabel: 'nav:small_business_sub_label',
          href: SMALL_BUSINESS_URL
        }
      ]
    }
  },
  {
    label: 'footer:resources',
    href: '#',
    subItems: [
      {
        label: 'footer:hr-okr-ebook',
        href: HR_OKR_EBOOK_URL,
        icon: 'book_nav'
      },
      { label: 'footer:blog', href: URL_BLOG, icon: 'blog_nav' },
      {
        label: 'footer:help_center',
        href: URL_HELP_CENTER,
        icon: 'help_nav'
      }
    ]
  },
  {
    label: 'footer:contact',
    href: CONTACT_US_URL,
    target: '_self'
  },
  {
    label: 'footer:pricing',
    href: PRICING_URL,
    target: '_self'
  }
]

const HeaderLandingPage: FC<{
  isHidRequestDemoButton?: boolean
  customContainerHeader?: string
  animationOffset?: number
}> = ({
  isHidRequestDemoButton = true,
  customContainerHeader,
  animationOffset
}) => {
  const { t, i18n } = useTranslation()
  const { scrollY } = useScrollPosition()
  const isHavingAnimation = !!animationOffset

  return (
    <div
      className={cn(
        'z-[90] border-b sticky top-0 bg-blue-50',
        // when having animation, on first visit, without scrolling, bg is transparent
        // bg will turn to blue when start scrolling
        // bg will turn to white when scroll to the offset (over the hero section)
        {
          'tablet:fixed tablet:inset-x-0 tablet:bg-transparent tablet:border-b-0':
            isHavingAnimation,
          'tablet:bg-blue-50 tablet:border-b': isHavingAnimation && scrollY > 0,
          'tablet:bg-white': isHavingAnimation && scrollY >= animationOffset
        },
        customContainerHeader
      )}>
      <LPContainer>
        <div
          className={`flex justify-between items-center py-[24px] tablet:py-[20px]`}>
          <div className="flex items-center">
            <Link href={pathConfiguration?.default}>
              <Image
                src={ICONS_SOCIAL?.hireforce_text}
                height={32}
                width={133}
                alt="hireforce-logo"
                quality={100}
              />
            </Link>

            {/* Desktop Navigation */}
            <NavigationBar content={navContent} />
          </div>

          {/* Desktop actions group Navigation */}
          <div className="hidden items-center gap-6 tablet:flex">
            <Link
              href={URL_REGISTER_APP}
              target="_blank"
              className="font-medium text-[17px] text-gray-900">
              {`${t('lp:try_for_free')}`}
            </Link>
            <Link
              href={URL_LOGIN_APP}
              target="_blank"
              className="font-medium text-[17px] text-gray-900">
              {`${t('lp:sign_in')}`}
            </Link>

            {isHidRequestDemoButton && (
              <Link
                href={pathConfiguration.contactRequest}
                locale={i18n?.language}>
                <Button
                  size="md"
                  label={`${t('lp:book_a_demo')}`}
                  type="quaternary"
                  className="text-lg"
                />
              </Link>
            )}
          </div>

          {/* Mobile Navigation Icon */}
          <MobileNavigation navList={navContent} />
        </div>
      </LPContainer>
    </div>
  )
}

export default HeaderLandingPage
