import { cn } from '~/core/ui/utils'
import { NavItem } from '.'
import SubItem from './SubItem'
import { t } from 'i18next'

const DropdownContent = ({
  item,
  isOpen
}: {
  item: NavItem
  isOpen?: boolean
}) => {
  return (
    <div
      className={cn(
        'dropdown-menu absolute z-20 mt-2 w-max bg-white border rounded-lg border-gray-100 shadow-md',
        'flex',
        { hidden: !isOpen }
      )}>
      <div className="px-2 py-4">
        {item.subItems?.map((subItem, subIndex) => (
          <SubItem key={subIndex} subItem={subItem} target={item.target} />
        ))}
      </div>
      {item.rightContent ? (
        <div className="w-[344px] bg-primary-50 bg-opacity-20 pt-5">
          {item.rightContent.title ? (
            <p className="text-primary-400 text-base px-6">
              {t('lp:' + item.rightContent.title)}
            </p>
          ) : null}
          <div className="p-2">
            {item.rightContent.subItems.map((subItem, subIndex) => (
              <SubItem
                key={subIndex}
                subItem={subItem}
                target={item.target}
                smallText
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default DropdownContent
