import Link from 'next/link'
import { t } from 'i18next'
import { cn } from '~/core/ui/utils'

export type SubItemProps = {
  label: string
  subLabel?: string
  icon?: string
  href: string
  target?: string
}

const SubItem = ({
  subItem,
  target,
  smallText
}: {
  subItem: SubItemProps
  target?: string
  smallText?: boolean
}) => {
  return (
    <Link
      href={subItem.href}
      target={target || '_blank'}
      className={`block px-4 py-2 hover:bg-gray-100 rounded-lg`}>
      <div className="flex gap-3 items-center">
        {subItem.icon && (
          <img
            src={`/img/feature/lp/${subItem.icon}.png`}
            alt="user_permission"
            width={40}
            className=""
          />
        )}
        <div className="flex flex-col gap-[2px]">
          <span className="font-medium text-lg">{`${t('lp:' + subItem.label)}`}</span>
          {subItem.subLabel && (
            <span
              className={cn(
                'leading-6 text-gray-700',
                smallText ? 'text-sm' : 'text-base'
              )}>{`${t('lp:' + subItem.subLabel)}`}</span>
          )}
        </div>
      </div>
    </Link>
  )
}

export default SubItem
