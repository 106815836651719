import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import Link from 'next/link'
import { ICONS } from '~/core/constants/resources'
import Image from 'next/image'
import { SubItemProps } from './SubItem'
import DropdownContent from './DropdownContent'

export type NavItem = {
  label: string
  href: string
  subItems?: SubItemProps[]
  target?: string
  rightContent?: {
    title?: string
    subItems: SubItemProps[]
  }
}

export type NavItemsProps = NavItem[]

const NavigationBar = ({ content }: { content: NavItemsProps }) => {
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null)

  const toggleDropdown = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index)
  }

  const closeDropdown = () => {
    setDropdownOpen(null)
  }

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (!target.closest('.dropdown-toggle')) {
        closeDropdown()
      }
    }

    window.addEventListener('scroll', closeDropdown)
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
      window.removeEventListener('scroll', closeDropdown)
    }
  }, [])

  return (
    <nav>
      <div className="hidden tablet:flex ml-16 navigation-menu">
        {content.map((item, index) => {
          const key = item.label
            .replace(/\s+/g, '-')
            .toLowerCase()
            .concat(`-${index}`)
          const hasSub = item.subItems && item.subItems?.length > 0

          return (
            <div className="relative" key={key}>
              {hasSub ? (
                <>
                  <button
                    type="button"
                    className={`dropdown-toggle flex items-center gap-2 cursor-pointer border border-transparent rounded-md px-4 py-1.5`}
                    onClick={() => toggleDropdown(index)}>
                    <span className="pointer-events-none select-none font-medium text-[17px] text-gray-900">
                      {`${t('lp:' + item.label)}`}
                    </span>
                    <Image
                      src={ICONS.chevronDown}
                      width={0}
                      height={0}
                      alt={`chevronDown${index}`}
                      className={`w-3 h-auto transition-all transform ${index === dropdownOpen && '-rotate-180'}`}
                    />
                  </button>
                  <DropdownContent
                    item={item}
                    isOpen={dropdownOpen === index}
                  />
                </>
              ) : (
                <Link
                  href={item.href}
                  target={item.target || '_blank'}
                  className="block font-medium text-[17px] text-gray-900 px-4 py-1.5">
                  {`${t('lp:' + item.label)}`}
                </Link>
              )}
              {/* <li className="flex items-center cursor-pointer border border-transparent hover:bg-gray-100 rounded-md px-4 py-1.5 font-medium text-[17px] text-gray-900">
                {`${t('lp:' + item.label)}`}
                {hasSub && (
                  <ChevronDownIcon className="ml-1 inline-block h-4 w-4 text-gray-900" />
                )}
              </li> */}
            </div>
          )
        })}
      </div>
    </nav>
  )
}
export default NavigationBar
