import { useEffect, useState } from 'react'

type ScrollPosition = {
  scrollX: number
  scrollY: number
}

const useScrollPosition = () => {
  const [position, setPosition] = useState<ScrollPosition>({
    scrollX: 0,
    scrollY: 0
  })

  useEffect(() => {
    const handleScroll = () => {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return position
}

export default useScrollPosition
