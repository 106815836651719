import { FCC } from '~/core/@types/global'
import HeaderLandingPage from './components/HeaderLandingPage'
import FooterLandingPage from './components/FooterLandingPage'

interface LayoutLandingPageProps {
  isHidRequestDemoButton?: boolean
  customContainerHeader?: string
  animationOffset?: number
}

const LayoutLandingPage: FCC<LayoutLandingPageProps> = ({
  children,
  isHidRequestDemoButton,
  customContainerHeader,
  animationOffset
}) => {
  return (
    <div className="relative">
      <HeaderLandingPage
        isHidRequestDemoButton={isHidRequestDemoButton}
        customContainerHeader={customContainerHeader}
        animationOffset={animationOffset}
      />
      {children}
      <FooterLandingPage />
    </div>
  )
}

export default LayoutLandingPage
